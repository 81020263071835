exports.components = {
  "component---src-pages-acabamentos-tsx": () => import("./../../../src/pages/acabamentos.tsx" /* webpackChunkName: "component---src-pages-acabamentos-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-designers-index-tsx": () => import("./../../../src/pages/designers/index.tsx" /* webpackChunkName: "component---src-pages-designers-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-produtos-tsx": () => import("./../../../src/pages/produtos.tsx" /* webpackChunkName: "component---src-pages-produtos-tsx" */),
  "component---src-pages-sobre-a-tumar-tsx": () => import("./../../../src/pages/sobre-a-tumar.tsx" /* webpackChunkName: "component---src-pages-sobre-a-tumar-tsx" */),
  "component---src-template-template-designer-single-tsx": () => import("./../../../src/template/template-designer-single.tsx" /* webpackChunkName: "component---src-template-template-designer-single-tsx" */),
  "component---src-template-template-finishes-tsx": () => import("./../../../src/template/template-finishes.tsx" /* webpackChunkName: "component---src-template-template-finishes-tsx" */),
  "component---src-template-template-product-category-tsx": () => import("./../../../src/template/template-product-category.tsx" /* webpackChunkName: "component---src-template-template-product-category-tsx" */),
  "component---src-template-template-product-single-tsx": () => import("./../../../src/template/template-product-single.tsx" /* webpackChunkName: "component---src-template-template-product-single-tsx" */)
}

